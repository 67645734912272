import React, { useState, useEffect } from 'react';

const WobbleButton = ({ svgContent, top, left, reverse }) => {
  const [style, setStyle] = useState({ transform: 'scale(1.5)' });
  const [isHovered, setIsHovered] = useState(false); // Track hover state

  useEffect(() => {
    const handleMouseMove = (e) => {
      // Calculate the mouse position relative to the center of the viewport
      const x = e.clientX - window.innerWidth / 2;
      const y = e.clientY - window.innerHeight / 2;
      
      // Apply reverse direction if the `reverse` prop is true
      const directionMultiplier = reverse ? -0.01 : 0.01;
      const scaleValue = isHovered ? 1.6 : 1.5; // Use different scale based on hover state

      setStyle((prevStyle) => ({
        ...prevStyle,
        transform: `translate(${x * directionMultiplier}px, ${y * directionMultiplier}px) scale(${scaleValue})`
      }));
    };

    const resetTransform = () => {
      setStyle({ transform: 'scale(1.5)' });
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseleave', resetTransform);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseleave', resetTransform);
    };
  }, [reverse, isHovered]);

  return (
    <button
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        ...style,
        position: 'absolute',
        top,
        left,
      }}
    >
      {svgContent}
    </button>
  ); 
};

// Usage Example with Different Buttons
const App = () => {
  return (
    <div>
      <WobbleButton
        svgContent={
          <a
            href="https://www.instagram.com/nicefriendlyguy.svg"
            target="_blank"
            rel="noopener noreferrer"
          >
          <svg
          viewBox="0 0 119 120"
          fill="#61dafb"
          xmlns="http://www.w3.org/2000/svg"
          style={{ background: 'none' }}
          >
          <circle cx="59.4647" cy="59.5951" r="58.4647" stroke="#FFF600" strokeWidth="2" class="icon-circle" />
          <path
            d="M45.2615 24.1577H75.3242C86.7767 24.1577 96.0818 33.4628 96.0818 44.9153V74.978C96.0818 80.4833 93.8948 85.763 90.002 89.6558C86.1092 93.5486 80.8295 95.7356 75.3242 95.7356H45.2615C33.809 95.7356 24.5039 86.4305 24.5039 74.978V44.9153C24.5039 39.41 26.6909 34.1303 30.5837 30.2375C34.4765 26.3447 39.7562 24.1577 45.2615 24.1577ZM44.5457 31.3155C41.1287 31.3155 37.8516 32.6729 35.4353 35.0891C33.0191 37.5054 31.6617 40.7825 31.6617 44.1995V75.6938C31.6617 82.8158 37.4237 88.5778 44.5457 88.5778H76.04C79.457 88.5778 82.7341 87.2204 85.1504 84.8042C87.5666 82.3879 88.924 79.1108 88.924 75.6938V44.1995C88.924 37.0775 83.162 31.3155 76.04 31.3155H44.5457ZM79.082 36.6838C80.2685 36.6838 81.4064 37.1552 82.2454 37.9941C83.0843 38.8331 83.5557 39.971 83.5557 41.1575C83.5557 42.3439 83.0843 43.4818 82.2454 44.3208C81.4064 45.1598 80.2685 45.6311 79.082 45.6311C77.8956 45.6311 76.7577 45.1598 75.9187 44.3208C75.0797 43.4818 74.6084 42.3439 74.6084 41.1575C74.6084 39.971 75.0797 38.8331 75.9187 37.9941C76.7577 37.1552 77.8956 36.6838 79.082 36.6838ZM60.2928 42.0522C65.0388 42.0522 69.5903 43.9375 72.9461 47.2934C76.302 50.6492 78.1873 55.2007 78.1873 59.9467C78.1873 64.6926 76.302 69.2441 72.9461 72.6C69.5903 75.9558 65.0388 77.8411 60.2928 77.8411C55.5469 77.8411 50.9954 75.9558 47.6395 72.6C44.2837 69.2441 42.3984 64.6926 42.3984 59.9467C42.3984 55.2007 44.2837 50.6492 47.6395 47.2934C50.9954 43.9375 55.5469 42.0522 60.2928 42.0522ZM60.2928 49.21C57.4453 49.21 54.7144 50.3412 52.7009 52.3547C50.6873 54.3682 49.5562 57.0991 49.5562 59.9467C49.5562 62.7942 50.6873 65.5251 52.7009 67.5386C54.7144 69.5522 57.4453 70.6833 60.2928 70.6833C63.1404 70.6833 65.8713 69.5522 67.8848 67.5386C69.8983 65.5251 71.0295 62.7942 71.0295 59.9467C71.0295 57.0991 69.8983 54.3682 67.8848 52.3547C65.8713 50.3412 63.1404 49.21 60.2928 49.21Z"
            fill="black"
            class="icon-path"
          />
          </svg>
          </a>
        }
        reverse={true}
        top="7%"
        left="15%"
      />
      <WobbleButton
        svgContent={
          <a
            href="https://www.linkedin.com/in/gianni-contreras-0aa58a24a/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg
            viewBox="0 0 119 119"
            fill="#61dafb"
            xmlns="http://www.w3.org/2000/svg"
            style={{ background: 'none' }}
          >
            <circle
              cx="59.4647"
              cy="59.4647"
              r="58.4647"
              stroke="#FFF600"
              stroke-width="2"
              class="icon-circle"
            />
            <path
              d="M84.8262 25.7251C86.7793 25.7251 88.6525 26.501 90.0335 27.882C91.4146 29.2631 92.1905 31.1362 92.1905 33.0894V84.6392C92.1905 86.5923 91.4146 88.4655 90.0335 89.8465C88.6525 91.2276 86.7793 92.0035 84.8262 92.0035H33.2764C31.3232 92.0035 29.4501 91.2276 28.0691 89.8465C26.688 88.4655 25.9121 86.5923 25.9121 84.6392V33.0894C25.9121 31.1362 26.688 29.2631 28.0691 27.882C29.4501 26.501 31.3232 25.7251 33.2764 25.7251H84.8262ZM82.9851 82.7981V63.2828C82.9851 60.0992 81.7205 57.046 79.4693 54.7949C77.2182 52.5438 74.165 51.2791 70.9814 51.2791C67.8516 51.2791 64.2063 53.1938 62.4389 56.0659V51.9787H52.1657V82.7981H62.4389V64.6452C62.4389 61.81 64.7218 59.4902 67.557 59.4902C68.9242 59.4902 70.2354 60.0334 71.2021 61.0001C72.1689 61.9669 72.712 63.278 72.712 64.6452V82.7981H82.9851ZM40.1988 46.1978C41.8394 46.1978 43.4128 45.546 44.5729 44.3859C45.733 43.2258 46.3848 41.6524 46.3848 40.0118C46.3848 36.5874 43.6232 33.789 40.1988 33.789C38.5484 33.789 36.9656 34.4446 35.7986 35.6116C34.6316 36.7786 33.976 38.3614 33.976 40.0118C33.976 43.4362 36.7744 46.1978 40.1988 46.1978ZM45.3169 82.7981V51.9787H35.1174V82.7981H45.3169Z"
              fill="#000000"
              class="icon-path"
            />
          </svg>
          </a>
        }
        top="20%"
        left="80%"
      />
      <WobbleButton
        svgContent={
          <a
            href="#Contact"
            rel="noopener noreferrer"
          >
          <svg
            viewBox="0 0 102 102" 
            fill="#61dafb"
            xmlns="http://www.w3.org/2000/svg"
            style={{ background: 'none' }}
          >
            <circle
              cx="51"
              cy="51"
              r="49" 
              stroke="#FFF600"
              strokeWidth="2" 
              fill="#61dafb"
              className="icon-circle"
            />
            <path
              d="M28.2454 72.1824C26.735 72.1824 25.4425 71.645 24.3679 70.5704C23.2933 69.4958 22.755 68.2024 22.7532 66.6902V33.7373C22.7532 32.2269 23.2914 30.9344 24.3679 29.8598C25.4444 28.7852 26.7369 28.2469 28.2454 28.2451H72.1826C73.6929 28.2451 74.9863 28.7833 76.0628 29.8598C77.1393 30.9363 77.6766 32.2288 77.6748 33.7373V66.6902C77.6748 68.2005 77.1374 69.4939 76.0628 70.5704C74.9882 71.6469 73.6948 72.1842 72.1826 72.1824H28.2454ZM50.214 52.9598L72.1826 39.2294V33.7373L50.214 47.4677L28.2454 33.7373V39.2294L50.214 52.9598Z"
              fill="#000000"
              className="icon-path"
            />
          </svg>
          </a>
        }
        top="40%"
        left="10%"
      />
      {/* Add more buttons as needed */}
    </div>
  );
};

export default App;
